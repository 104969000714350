import React from 'react';
import { Helmet } from "react-helmet";
import Img from "gatsby-image";
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import RubberBand from 'react-reveal/RubberBand';
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';
import Jello from 'react-reveal/Jello';
import Layout from "../components/layout";
import Flip from 'react-reveal/Flip';
import ReactPlayer from "react-player";

const paraFour = {
    backgroundImage: 'url(../rec8vdXoHgLiiq5af.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraFive = {
    backgroundImage: 'url(../recqIDtZ81IygjCbc.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const recJphViDMZHineIf = {
    backgroundImage: 'url(../recJphViDMZHineIf.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const paraSeven = {
    backgroundImage: 'url(../recDkySAf4jDZcnSR.jpg)',
    height: '50vh',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
};

const credit = {
    background: 'linear-gradient(rgba(255, 184, 29, 0.45), rgba(255, 184, 29, 0.45)), url(../recQMPYluhJKwzabC.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    backgroundPosition: 'center center',
}

export default ({ data }) => (
    <div>
        <Layout>
        <Helmet>
            <title>{data.meta.data.story} | Oberlin Photo Stories</title>
            
            <meta name="title" content={data.meta.data.story} />
            <meta name="description" content={data.meta.data.description} />
            
            <meta property="og:type" content="website" />
            <meta property="og:url" content={data.meta.data.metaurl} />
            <meta property="og:title" content={data.meta.data.story} />
            <meta property="og:description" content={data.meta.data.description} />
            <meta property="og:image" content="https://photo-stories.oberlin.edu/reccb4ZdHlG83WeC2.jpg" />
            <meta property="og:image:width" content="2000" />
            <meta property="og:image:height" content="1333" />
            <meta property="og:image:alt" content={data.meta.data.alt} />
            <meta property="fb:app_id" content="1571317633107125" />
            
            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content={data.meta.data.metaurl} />
            <meta property="twitter:title" content={data.meta.data.story} />
            <meta property="twitter:description" content={data.meta.data.description} />
            <meta property="twitter:image" content="https://photo-stories.oberlin.edu/reccb4ZdHlG83WeC2.jpg" />
            <meta property="twitter:image:alt" content={data.meta.data.alt} />
        </Helmet>
        {/* hero */}
        <header>
            <div className="carousel slide carousel-fade" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active" data-interval="3000" data-pause="false">
                        <Img fluid={data.reccb4ZdHlG83WeC2.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccb4ZdHlG83WeC2.data.alt} className="hero" />
                        <div className="title default text-center">
                            <h1 className="display-3 text-light">{data.title.data.title}</h1>
                            {/* <p className="lead text-light">- {data.subtitle.data.title} -</p> */}
                            <a href="#ss" aria-label="Skip to main content" tabindex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000" data-pause="false">
                        <Img fluid={data.reccb4ZdHlG83WeC2.data.file.localFiles[1].childImageSharp.fluid} alt={data.reccb4ZdHlG83WeC2.data.alt} className="hero" />
                        <div className="title default text-center">
                            <h1 className="display-3 text-light">{data.title.data.title}</h1>
                            {/* <p className="lead text-light">- {data.subtitle.data.title} -</p> */}
                            <a href="#ss" aria-label="Skip to main content" tabindex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000" data-pause="false">
                        <Img fluid={data.reccb4ZdHlG83WeC2.data.file.localFiles[2].childImageSharp.fluid} alt={data.reccb4ZdHlG83WeC2.data.alt} className="hero" />
                        <div className="title default text-center">
                            <h1 className="display-3 text-light">{data.title.data.title}</h1>
                            {/* <p className="lead text-light">- {data.subtitle.data.title} -</p> */}
                            <a href="#ss" aria-label="Skip to main content" tabindex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000" data-pause="false">
                        <Img fluid={data.reccb4ZdHlG83WeC2.data.file.localFiles[3].childImageSharp.fluid} alt={data.reccb4ZdHlG83WeC2.data.alt} className="hero" />
                        <div className="title default text-center">
                            <h1 className="display-3 text-light">{data.title.data.title}</h1>
                            {/* <p className="lead text-light">- {data.subtitle.data.title} -</p> */}
                            <a href="#ss" aria-label="Skip to main content" tabindex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                    <div className="carousel-item" data-interval="3000" data-pause="false">
                        <Img fluid={data.reccb4ZdHlG83WeC2.data.file.localFiles[4].childImageSharp.fluid} alt={data.reccb4ZdHlG83WeC2.data.alt} className="hero" />
                        <div className="title default text-center">
                            <h1 className="display-3 text-light">{data.title.data.title}</h1>
                            {/* <p className="lead text-light">- {data.subtitle.data.title} -</p> */}
                            <a href="#ss" aria-label="Skip to main content" tabindex="0"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        {/* <header>
        <div className="wrapper">
            <div style={parallax} className="hero parallax" role="img" aria-label={data.hero.data.alt}></div>
            <div className="title text-center">
                <h1 className="display-3 text-light">{data.title.data.title}</h1>
                <p className="lead text-light">- {data.subtitle.data.title} -</p>
                <a href="#ss" aria-label="Skip to main content"><span className="fas fa-chevron-down fa-3x mt-5 text-white"></span></a>
            </div>
        </div>
        </header> */}

        <main>
            <div className="anchor" id="ss"></div>
            <section style={{marginTop:`80px`}}>
                <div className="bg-light">
                <p className="h2 text-center py-5 text-dark">Senior Studio</p>
                </div>
            </section>
            {/* Matterport */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
                <div className="col-lg-10 col mx-auto">
                <div dangerouslySetInnerHTML={{
                    __html: data.rec1TuMqVwTitMfXq.data.text.childMarkdownRemark.html,
                    }} />
                <a href={data.rec6Ex4192Xv09kOr.data.url} className="btn btn-outline-dark mt-3" target="_blank" rel="noopener noreferrer">{data.rec6Ex4192Xv09kOr.data.title}</a>
                </div>
            </div> 
            </section>
            
            {/* Xiaoyun Gong */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
                <div dangerouslySetInnerHTML={{
                __html: data.recCLE4Pz1fMbli0p.data.text.childMarkdownRemark.html,
                }} />
                <p className="mt-3"><em>Within and Without</em>, Installation</p>
                <Img fluid={data.recDglLfRYVn8y34m.data.file.localFiles[0].childImageSharp.fluid} alt={data.recDglLfRYVn8y34m.data.alt} className="col-lg-10 mx-auto mt-3 mb-5" />
                <Fade bottom>
                <div className="row mb-5">
                    <div className="col-lg-6 mb-lg-0 mb-3">
                        <Img fixed={data.receN1LUoxM4Cmcc5.data.file.localFiles[0].childImageSharp.fixed} alt={data.receN1LUoxM4Cmcc5.data.alt} className="w-100" />
                    </div>
                    <div className="col-lg-6 story">
                        <div dangerouslySetInnerHTML={{
                        __html: data.receN1LUoxM4Cmcc5.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
                </Fade>
                <Img fluid={data.reclpwqab7ip3nL4F.data.file.localFiles[0].childImageSharp.fluid} alt={data.reclpwqab7ip3nL4F.data.alt} />
            </div>
            </section>

            {/* Alison Qin */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
                <div dangerouslySetInnerHTML={{
                    __html: data.rec3hPeh7M3S6BcDx.data.text.childMarkdownRemark.html,
                    }} />
                <div className="mt-2" dangerouslySetInnerHTML={{
                __html: data.reccK87YFmqAW9UiZ.data.text.childMarkdownRemark.html,
                }} />
                <Fade bottom>
                <div className="col-lg-10 col mx-auto mt-3">
                <Img fluid={data.reccK87YFmqAW9UiZ.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccK87YFmqAW9UiZ.data.alt} />
                </div>
                </Fade>
            </div>
            </section>

            {/* Zoe Iatridis */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
                <div dangerouslySetInnerHTML={{
                __html: data.recewBxwURWxdAy6N.data.text.childMarkdownRemark.html,
                }} />
                <Fade>
                <Img fluid={data.recmCeEBs9nufizJ1.data.file.localFiles[0].childImageSharp.fluid} alt={data.recmCeEBs9nufizJ1.data.alt} className="mt-3" />
                <div dangerouslySetInnerHTML={{
                __html: data.rechj7ieMfYku2fgC.data.text.childMarkdownRemark.html,
                }} className="mt-2" style={{fontSize:`90%`}} />
                </Fade>
            </div>
            <Fade>
            <div style={paraFour} role="img" aria-label={data.rec8vdXoHgLiiq5af.data.alt} className="parallax mt-5"></div>
            </Fade>
            <div className="container mt-2 mb-5 story">
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec8vdXoHgLiiq5af.data.text.childMarkdownRemark.html,
                    }} />
                <Fade bottom>
                <div className="col-lg-6 col mx-auto mt-5">
                <Img fluid={data.recaM4Af8JMzSBApt.data.file.localFiles[0].childImageSharp.fluid} alt={data.recaM4Af8JMzSBApt.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recaM4Af8JMzSBApt.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
            </div>
            </section>

            {/* Jenna Ko */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
                <div dangerouslySetInnerHTML={{
                __html: data.recAmCbsORiAHOi14.data.text.childMarkdownRemark.html,
                }} />
                <blockquote className="mt-3 pullquote">
                <p className="mb-0">{data.recITITKyD3Oe8yD7.data.title}</p>
                <footer className="pullquote-footer">{data.recITITKyD3Oe8yD7.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
                </blockquote>
                <Fade left>
                <div className="row mt-3 text-center">
                {data.rectaKqi16tyJg5Ho.nodes.map(node => (
                    <div className="col-lg-6 col-10 mx-auto mb-3">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    </div>
                ))}
                </div>
                </Fade>
                <Fade right>
                <div className="row mt-3 text-center">
                {data.rec9e5uTds5EOUP8w.nodes.map(node => (
                    <div className="col-lg-6 col-10 mx-auto mb-3">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    </div>
                ))}
                </div>
                </Fade>
                <div dangerouslySetInnerHTML={{
                __html: data.recaghv6fF8TbJQu8.data.text.childMarkdownRemark.html,
                }} style={{fontSize:`90%`}} />
                <div className="col-lg-8 col mx-auto my-5">
                <Img fluid={data.recYhWt8dc7WB6ccV.data.file.localFiles[0].childImageSharp.fluid} alt={data.recYhWt8dc7WB6ccV.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recYhWt8dc7WB6ccV.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </div>
            <div style={paraFive} role="img" aria-label={data.recqIDtZ81IygjCbc.data.alt} className="parallax"></div>
            <div className="container mt-5 story">
                <Pulse>
                <div className="row">
                    <div className="col-lg-4 col-8 mb-lg-0 mb-3 mx-auto">
                    <Img fluid={data.recvD8l9g5afX7C5G.data.file.localFiles[0].childImageSharp.fluid} alt={data.recvD8l9g5afX7C5G.data.alt} className="w-100" />
                    </div>
                    <div className="col-lg-6 mb-lg-0 mb-3 mx-auto">
                    <Img fluid={data.rec8lSIDUul9d9udL.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec8lSIDUul9d9udL.data.alt} className="w-100" />
                    </div>
                </div>
                </Pulse>
                <div dangerouslySetInnerHTML={{
                __html: data.recLGEV3ivNF0dQu0.data.text.childMarkdownRemark.html,
                }} className="mt-2 mb-4" style={{fontSize:`90%`}} />
                <Fade bottom>
                <div className="col-lg-10 col mx-auto mt-5">
                <Img fluid={data.recIYSsXPDukXf6T8.data.file.localFiles[0].childImageSharp.fluid} alt={data.recIYSsXPDukXf6T8.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recIYSsXPDukXf6T8.data.text.childMarkdownRemark.html,
                }} />
                </div>
                </Fade>
            </div>
            </section>

            {/* Micaela Pirzio-Biroli */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recW4MNfKubCALsqA.data.text.childMarkdownRemark.html,
                }} />
            <div dangerouslySetInnerHTML={{
                __html: data.rec0djehY3g6hH1N2.data.text.childMarkdownRemark.html,
                }} className="mt-3" />
            <RubberBand>
            <div className="col-lg-10 col mx-auto">
            <Img fluid={data.recVCXf2Cq0HwOfoL.data.file.localFiles[0].childImageSharp.fluid} alt={data.recVCXf2Cq0HwOfoL.data.alt} />
            </div>
            </RubberBand>
            </div>
            </section>

            {/* Liam Ashbrook */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recjpo0cWHe8L4uXu.data.text.childMarkdownRemark.html,
                }} />
            <div className="row mt-3">
            {data.reccD28kIeSr6ukvt.nodes.map(node => (
                <div className="col-lg-4 col-8 mb-3 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="w-100" />
                    <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} className="mt-2" />
                </div>
            ))}
            </div>
            </div>
            </section>

            {/* Micaela Pirzio-Biroli */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recvy6zGaRhxtp9WJ.data.text.childMarkdownRemark.html,
                }} />
            </div>
            <div style={recJphViDMZHineIf} role="img" aria-label={data.recJphViDMZHineIf.data.alt} className="parallax mt-3"></div>
            <div className="container story">
            <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recJphViDMZHineIf.data.text.childMarkdownRemark.html,
                }} />
            <Fade top>
                <div className="col-lg-10 col mx-auto mt-5">
                <Img fluid={data.recsR435r7Nmd470B.data.file.localFiles[0].childImageSharp.fluid} alt={data.recsR435r7Nmd470B.data.alt} />
                <div className="mt-2" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recsR435r7Nmd470B.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Fade>
            </div>
            </section>

            {/* Maddie Shaw */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recfaB7Tsu6va5vXI.data.text.childMarkdownRemark.html,
                }} />
            <Pulse>
                <div className="row mt-3">
                {data.reczveSygveqQkFPB.nodes.map(node => (
                    <div className="col-lg-8 col-12 mx-auto mb-3">
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                    <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                        __html: node.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                ))}
                </div>
                <div className="col-lg-6 col-8 mx-auto">
                <Img fluid={data.recPySxjwdAdJlsFF.data.file.localFiles[0].childImageSharp.fluid} alt={data.recPySxjwdAdJlsFF.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recPySxjwdAdJlsFF.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Pulse>
            </div>
            </section>

            {/* Billy Blender */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.rec5CVCGytuXmzg9f.data.text.childMarkdownRemark.html,
                }} />
            <Fade bottom>
            <div className="col-lg-10 col mx-auto mt-5">
                <Img fluid={data.recQi00bP8akWijXD.data.file.localFiles[0].childImageSharp.fluid} alt={data.recQi00bP8akWijXD.data.alt} />
                <div dangerouslySetInnerHTML={{
                __html: data.recQi00bP8akWijXD.data.text.childMarkdownRemark.html,
                }} className="mt-2" style={{fontSize:`90%`}} />
                <Img fluid={data.rec179vFpOZ3cGzzb.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec179vFpOZ3cGzzb.data.alt} className="mt-5" />
                <div dangerouslySetInnerHTML={{
                __html: data.rec5WRELod2psevSk.data.text.childMarkdownRemark.html,
                }} className="mt-2" style={{fontSize:`90%`}} />
                <Img fluid={data.recD84QQVpb0e0kjd.data.file.localFiles[0].childImageSharp.fluid} alt={data.recD84QQVpb0e0kjd.data.alt} className="mt-5" />
                <p className="mt-2" style={{fontSize:`90%`}}><em>Retracing My Steps</em>.</p>
            </div>
            </Fade>
            </div>
            </section>

            {/* Alice Lawton */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recatzeYDQ0WxdC9h.data.text.childMarkdownRemark.html,
                }} />
            <Jello>
                <div className="col-lg-8 col mx-auto mt-3">
                <Img fluid={data.recOvawqHzdPg272O.data.file.localFiles[0].childImageSharp.fluid} alt={data.recOvawqHzdPg272O.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recOvawqHzdPg272O.data.text.childMarkdownRemark.html,
                }} />
                </div>
                <div className="col-lg-6 col mx-auto">
                <Img fluid={data.recD1qc0Cxh1ORpfY.data.file.localFiles[0].childImageSharp.fluid} alt={data.recD1qc0Cxh1ORpfY.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recD1qc0Cxh1ORpfY.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Jello>
            </div>
            </section>

            {/* Olivia Berke */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.rec5MiBx1ik3iJmpn.data.text.childMarkdownRemark.html,
                }} />
            <Bounce>
                <div className="row mt-3">
                {data.recGHgPFI1Zd8Tm9m.nodes.map(node => (
                    <div className="col-lg-4 col-10 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="w-100" />
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} className="mt-2" />
                    </div>
                ))}
                </div>
                <div className="row">
                {data.recfXIYRUntXxcPkC.nodes.map(node => (
                    <div className="col-lg-4 col-10 mb-lg-0 mb-3 mx-auto" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} className="w-100" />
                                <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                            __html: node.data.text.childMarkdownRemark.html,
                            }} className="mt-2" />
                    </div>
                ))}
                </div>
            </Bounce>
            </div>
            </section>

            {/* Valentina Zhang */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.rec3KF6rB5mZNVohv.data.text.childMarkdownRemark.html,
                }} />
            <Zoom>
            <div className="col-lg-10 col mx-auto mt-3">
                <Img fluid={data.recxnfW0etbtdwKAX.data.file.localFiles[0].childImageSharp.fluid} alt={data.recxnfW0etbtdwKAX.data.alt} />
            </div>
            <div className="row mt-5">
                <div className="col-lg-3 col-8 mb-3 mx-auto">
                <Img fluid={data.rec6v4JO1rYweqiA4.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec6v4JO1rYweqiA4.data.alt} />
                </div>
                <div className="col-lg-6 col-12 mb-3 mx-auto">
                <Img fluid={data.recfcQViiPkykcP9Z.data.file.localFiles[0].childImageSharp.fluid} alt={data.recfcQViiPkykcP9Z.data.alt} />
                </div>
                <div className="col-lg-3 col-8 mb-3 mx-auto">
                <Img fluid={data.recCtf9OLt6C1LcCM.data.file.localFiles[0].childImageSharp.fluid} alt={data.recCtf9OLt6C1LcCM.data.alt} />
                </div>
            </div>
            <div className="row">
            {data.rec495M62J3QgeTOv.nodes.map(node => (
                <div className="col-lg-4 col-8 mb-lg-0 my-3 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                </div>
            ))}
            </div>
            <div className="row mt-3 text-center">
            {data.recshUgdHKfZ0r2hm.nodes.map(node => (
                <div className="col-lg-10 col-12 mx-auto my-3">
                <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} key={node.recordId} className="img-fluid" />
                </div>
            ))}
            </div>
            </Zoom>
            <p className="mb-5 col-lg-10" style={{fontSize:`90%`}}><em>Untitled (3)</em>. 2020. Gausses, gesso, balloon, and tulle.<br /><em>Untitled (4)</em>. 2020.  Gausses, gesso, balloon, tree branch, and leaves.<br /><em>Untitled (5)</em>. 2020.  Cardboard, gesso, glue gun, white paint, and tree branches.</p>
            </div>
            <Fade>
            <div style={paraSeven} role="img" aria-label={data.recDkySAf4jDZcnSR.data.alt} className="parallax"></div>
            </Fade>
            <div className="container my-5 story">
            <Fade>
                <div className="row">
                {data.recNfk4AAvTna25uz.nodes.map(node => (
                    <div className="col-lg-6 mb-3" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                <p style={{fontSize:`90%`}}><em>Untitled (1)</em>. 2020. Tree branch, fishing line, white glue, and balloon.</p>
                </div>
                <div className="row mt-3">
                {data.rec7hAUlQBLogxYyQ.nodes.map(node => (
                    <div className="col-lg-6 mb-3" key={node.recordId}>
                        <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    </div>
                ))}
                <p style={{fontSize:`90%`}}><em>Untitled (2)</em>. 2020. Shrub, fishing line, nail polish, balloon.</p>
                </div>
            </Fade>
            </div>
            </section>

            {/* Mikaela Howard */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recMVE78rscBD6F0I.data.text.childMarkdownRemark.html,
                }} />
            <p className="mt-3"><em>Aversion, and Purity</em>. Assemblage</p>
            <Fade bottom>
                <div className="col-lg-6 col-10 mx-auto mt-3">
                <Img fluid={data.recPtQEo7njUvIzPa.data.file.localFiles[0].childImageSharp.fluid} alt={data.recPtQEo7njUvIzPa.data.alt} />
                </div>
            </Fade>
            </div>
            </section>

            {/* Bridget Conway */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recakLbLEsdVzCOUV.data.text.childMarkdownRemark.html,
                }} />
            <Flip>
                <div className="col-lg-10 col mt-3 mx-auto">
                <Img fluid={data.reczGUTGfrVYdaRSV.data.file.localFiles[0].childImageSharp.fluid} alt={data.reczGUTGfrVYdaRSV.data.alt} />
                <p className="mt-2" style={{fontSize:`90%`}}><em>Yes, It’s True; You Can Never Go Home Again</em>. Wood, plexiglass, oil paint, and screenprint</p>
                </div>
                <div className="col-lg-6 col-10 mt-4 mx-auto">
                <Img fluid={data.recHmlPfE5aVxFBm4.data.file.localFiles[0].childImageSharp.fluid} alt={data.recHmlPfE5aVxFBm4.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recHmlPfE5aVxFBm4.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Flip>
            </div>
            </section>

            {/* Grace McCallister */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recSzyAMRYjr0x9cU.data.text.childMarkdownRemark.html,
                }} />
            <div className="row mt-3">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3 mx-auto">
                <Img fluid={data.rec33UXJVbBnqseyq.data.file.localFiles[0].childImageSharp.fluid} alt={data.rec33UXJVbBnqseyq.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.rec33UXJVbBnqseyq.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-4 mb-lg-0 mb-3 mx-auto">
                <Img fluid={data.reczFfvY8Mp3vmkI1.data.file.localFiles[0].childImageSharp.fluid} alt={data.reczFfvY8Mp3vmkI1.data.alt} />
                        <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                    __html: data.reczFfvY8Mp3vmkI1.data.text.childMarkdownRemark.html,
                    }} />
                </div>
                </Fade>
            </div>
            </div>
            </section>

            {/* Ivy Miller */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.rec7ryF21Kg15xPoK.data.text.childMarkdownRemark.html,
                }} />
            <Fade bottom>
                <div className="col-lg-10 col mx-auto mt-3">
                <Img fluid={data.reco5r14z9Gm0DcoT.data.file.localFiles[0].childImageSharp.fluid} alt={data.reco5r14z9Gm0DcoT.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.reco5r14z9Gm0DcoT.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Fade>
            </div>
            </section>

            <section style={{marginTop:`80px`}}>
                <div className="bg-light">
                <p className="h2 text-center py-5 text-dark">Other Student Submissions</p>
                </div>
            </section>

            {/* Beaux Watwood */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recCxTSPrCH8pbsjJ.data.text.childMarkdownRemark.html,
                }} />
            <div dangerouslySetInnerHTML={{
                __html: data.recTHZvOUhw2FoybY.data.text.childMarkdownRemark.html,
                }} className="h4 mt-3" />
            <ReactPlayer
                url="https://vimeo.com/493498235/3da3fbe890"
                controls="true"
                width="100%"
                height="50vh"
            />
            </div>
            </section>

            {/* Maya McCollum */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recTNdF3SKj6G03at.data.text.childMarkdownRemark.html,
                }} />
            <div dangerouslySetInnerHTML={{
                __html: data.recvZQENu7Qd4Gz1A.data.text.childMarkdownRemark.html,
                }} className="mt-3" />
            <Fade>
            <blockquote className="mt-5 pullquote">
            <p className="mb-0">{data.recBjPeFgANb7Vq4m.data.title}</p>
            <footer className="pullquote-footer">{data.recBjPeFgANb7Vq4m.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            </Fade>
            <div className="row">
                <Fade left>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fluid={data.reczjIlNqIVtgV5vS.data.file.localFiles[0].childImageSharp.fluid} alt={data.reczjIlNqIVtgV5vS.data.alt} className="w-100" />
                </div>
                </Fade>
                <Fade right>
                <div className="col-lg-6 mb-lg-0 mb-3">
                <Img fluid={data.recX1jcoy62ZyhWbC.data.file.localFiles[0].childImageSharp.fluid} alt={data.recX1jcoy62ZyhWbC.data.alt} className="w-100" />
                </div>
                </Fade>
            </div>
            <div className="row mt-5">
                <div className="col-lg-5 col-8 mb-lg-0 mx-auto">
                    <Img fluid={data.recfVKgj5E2n79UrU.data.file.localFiles[0].childImageSharp.fluid} alt={data.recfVKgj5E2n79UrU.data.alt} />
                </div>
                <div className="col-lg-6 col-8 justify-content-lg-center story mx-auto mt-2 mt-lg-0" style={{display:'flex', alignItems:'center'}}>
                    <div dangerouslySetInnerHTML={{
                    __html: data.recfVKgj5E2n79UrU.data.text.childMarkdownRemark.html,
                    }} className="d-lg-block d-none" />
                    <div className="mb-4 d-lg-none" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recfVKgj5E2n79UrU.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </div>
            <Fade>
            <div dangerouslySetInnerHTML={{
                __html: data.recHaLOvkj5jGnT6m.data.text.childMarkdownRemark.html,
                }} className="mt-2" style={{fontSize:`90%`}} />
            <blockquote className="mt-5 pullquote">
            <p className="mb-0">{data.recqFAoSKfCt3JMGW.data.title}</p>
            <footer className="pullquote-footer">{data.recqFAoSKfCt3JMGW.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            </Fade>
            <Zoom>
                <div className="col-lg-8 col mx-auto">
                <Img fluid={data.recyFyWa1zxQXgD4D.data.file.localFiles[0].childImageSharp.fluid} alt={data.recyFyWa1zxQXgD4D.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recyFyWa1zxQXgD4D.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Zoom>
            <Fade>
            <blockquote className="mt-5 pullquote">
            <p className="mb-0">{data.recm2y9GAuwKtgrl8.data.title}</p>
            <footer className="pullquote-footer">{data.recm2y9GAuwKtgrl8.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            </Fade>
            <Zoom>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.recj2w6gBRlNl1sxs.data.file.localFiles[0].childImageSharp.fluid} alt={data.recj2w6gBRlNl1sxs.data.alt} />
                <div className="mt-2 mb-4" style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
                __html: data.recj2w6gBRlNl1sxs.data.text.childMarkdownRemark.html,
                }} />
                </div>
            </Zoom>
            </div>
            </section>

            {/* Molly Thayer */}
            <section style={{marginTop:`80px`}}>
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.reczOorxV1P9L2uLt.data.text.childMarkdownRemark.html,
                }} />
            <blockquote className="my-3 pullquote">
            <p className="mb-0">{data.recEBTPkkSZAO9UdS.data.title}</p>
            <footer className="pullquote-footer">{data.recEBTPkkSZAO9UdS.data.text.childMarkdownRemark.rawMarkdownBody}</footer>
            </blockquote>
            <Fade left>
                <div className="col-lg-10 col mx-auto">
                <Img fluid={data.reccUl6HRhOAn9Pfb.data.file.localFiles[0].childImageSharp.fluid} alt={data.reccUl6HRhOAn9Pfb.data.alt} />
                </div>
            </Fade>
            <div dangerouslySetInnerHTML={{
            __html: data.rec6xa3WWvhf3jpbh.data.text.childMarkdownRemark.html,
            }} className="h4 mt-5" />
            <ReactPlayer
                url="https://vimeo.com/494117488/0ffd6abdc8"
                controls="true"
                width="100%"
                height="50vh"
            />
            <div style={{fontSize:`90%`}} dangerouslySetInnerHTML={{
            __html: data.rec1pIfxtAY6RLDGU.data.text.childMarkdownRemark.html,
            }} className="col-8 mx-auto mt-4 text-center" />
            </div>
            </section>

            {/* Rest */}
            <section style={{marginTop:`120px`}} className="bg-light py-4">
            <div className="container story">
            <div dangerouslySetInnerHTML={{
                __html: data.recB6Thu2BcD4HZvk.data.text.childMarkdownRemark.html,
                }} className="text-dark" />
            <Fade>
            <div className="row">
            {data.recw4QnsvDVzTZcSJ.nodes.map(node => (
                <div className="col-lg-4 col-8 mb-lg-3 mb-5 mx-auto" key={node.recordId}>
                    <Img fluid={node.data.file.localFiles[0].childImageSharp.fluid} alt={node.data.alt} />
                    <a href={node.data.url} className="btn btn-dark mt-3" target="_blank" rel="noopener noreferrer">{node.data.title}</a>
                </div>
            ))}
            </div>
            </Fade>
            </div>
            </section>

            {/* Credit */}
            <section>
                <div style={credit} role="img" aria-label={data.recQMPYluhJKwzabC.data.alt} className="py-5 mb-0 text-center">
                    <div className="credit-text">
                        <p className="h3 credit-title">{data.recQMPYluhJKwzabC.data.title}</p>
                        <div dangerouslySetInnerHTML={{
                        __html: data.recQMPYluhJKwzabC.data.text.childMarkdownRemark.html,
                        }} />
                    </div>
                </div>
            </section>
        </main>
        </Layout>
    </div>
)

export const query = graphql`
{
    meta: airtable(recordId: {eq: "reccb4ZdHlG83WeC2"}) {
        data {
          file {
            localFiles {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          alt
          story
          metaurl
          description
        }
      }

    hero: airtable(data: {identifier: {eq: "reccb4ZdHlG83WeC2"}}) {
        data {
            alt
        }
    }

    reccb4ZdHlG83WeC2: airtable(data: {identifier: {eq: "reccb4ZdHlG83WeC2"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(fit: COVER, quality: 90) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    title: airtable(data: {identifier: {eq: "rec4CC2awbSY5r7gI"}}) {
        data {
            title
        }
    }

    rec1TuMqVwTitMfXq: airtable(data: {identifier: {eq: "rec1TuMqVwTitMfXq"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec6Ex4192Xv09kOr: airtable(recordId: {eq: "rec6Ex4192Xv09kOr"}) {
        data {
            title
            url
        }
    }

    recCLE4Pz1fMbli0p: airtable(data: {identifier: {eq: "recCLE4Pz1fMbli0p"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recDglLfRYVn8y34m: airtable(data: {identifier: {eq: "recDglLfRYVn8y34m"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    receN1LUoxM4Cmcc5: airtable(recordId: {eq: "receN1LUoxM4Cmcc5"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fixed(height: 300, cropFocus: ATTENTION, quality: 100) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reclpwqab7ip3nL4F: airtable(data: {identifier: {eq: "reclpwqab7ip3nL4F"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec3hPeh7M3S6BcDx: airtable(data: {identifier: {eq: "rec3hPeh7M3S6BcDx"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reccK87YFmqAW9UiZ: airtable(data: {identifier: {eq: "reccK87YFmqAW9UiZ"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recewBxwURWxdAy6N: airtable(data: {identifier: {eq: "recewBxwURWxdAy6N"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rechj7ieMfYku2fgC: airtable(data: {identifier: {eq: "rechj7ieMfYku2fgC"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recmCeEBs9nufizJ1: airtable(data: {identifier: {eq: "recmCeEBs9nufizJ1"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec8vdXoHgLiiq5af: airtable(recordId: {eq: "rec8vdXoHgLiiq5af"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recaM4Af8JMzSBApt: airtable(data: {identifier: {eq: "recaM4Af8JMzSBApt"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recAmCbsORiAHOi14: airtable(data: {identifier: {eq: "recAmCbsORiAHOi14"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recaghv6fF8TbJQu8: airtable(data: {identifier: {eq: "recaghv6fF8TbJQu8"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recITITKyD3Oe8yD7: airtable(data: {identifier: {eq: "recITITKyD3Oe8yD7"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    rectaKqi16tyJg5Ho: allAirtable(filter: {data: {group: {eq: "studio8"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec9e5uTds5EOUP8w: allAirtable(filter: {data: {group: {eq: "studio9"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recYhWt8dc7WB6ccV: airtable(data: {identifier: {eq: "recYhWt8dc7WB6ccV"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recLGEV3ivNF0dQu0: airtable(data: {identifier: {eq: "recLGEV3ivNF0dQu0"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recqIDtZ81IygjCbc: airtable(recordId: {eq: "recqIDtZ81IygjCbc"}) {
        data {
            alt
        }
    }

    recvD8l9g5afX7C5G: airtable(recordId: {eq: "recvD8l9g5afX7C5G"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    rec8lSIDUul9d9udL: airtable(recordId: {eq: "rec8lSIDUul9d9udL"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recIYSsXPDukXf6T8: airtable(data: {identifier: {eq: "recIYSsXPDukXf6T8"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recW4MNfKubCALsqA: airtable(data: {identifier: {eq: "recW4MNfKubCALsqA"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec0djehY3g6hH1N2: airtable(data: {identifier: {eq: "rec0djehY3g6hH1N2"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recVCXf2Cq0HwOfoL: airtable(data: {identifier: {eq: "recVCXf2Cq0HwOfoL"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recCxTSPrCH8pbsjJ: airtable(data: {identifier: {eq: "recCxTSPrCH8pbsjJ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTHZvOUhw2FoybY: airtable(data: {identifier: {eq: "recTHZvOUhw2FoybY"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recTNdF3SKj6G03at: airtable(data: {identifier: {eq: "recTNdF3SKj6G03at"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recHaLOvkj5jGnT6m: airtable(data: {identifier: {eq: "recHaLOvkj5jGnT6m"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recvZQENu7Qd4Gz1A: airtable(data: {identifier: {eq: "recvZQENu7Qd4Gz1A"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recBjPeFgANb7Vq4m: airtable(data: {identifier: {eq: "recBjPeFgANb7Vq4m"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    reczjIlNqIVtgV5vS: airtable(recordId: {eq: "reczjIlNqIVtgV5vS"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recX1jcoy62ZyhWbC: airtable(recordId: {eq: "recX1jcoy62ZyhWbC"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recfVKgj5E2n79UrU: airtable(recordId: {eq: "recfVKgj5E2n79UrU"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recqFAoSKfCt3JMGW: airtable(data: {identifier: {eq: "recqFAoSKfCt3JMGW"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recyFyWa1zxQXgD4D: airtable(data: {identifier: {eq: "recyFyWa1zxQXgD4D"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recm2y9GAuwKtgrl8: airtable(data: {identifier: {eq: "recm2y9GAuwKtgrl8"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    recj2w6gBRlNl1sxs: airtable(data: {identifier: {eq: "recj2w6gBRlNl1sxs"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recjpo0cWHe8L4uXu: airtable(data: {identifier: {eq: "recjpo0cWHe8L4uXu"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reccD28kIeSr6ukvt: allAirtable(filter: {data: {group: {eq: "studio14"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                            text {
                        childMarkdownRemark {
                            html
                        }
                    }
            }
        }
    }

    recvy6zGaRhxtp9WJ: airtable(data: {identifier: {eq: "recvy6zGaRhxtp9WJ"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recJphViDMZHineIf: airtable(recordId: {eq: "recJphViDMZHineIf"}) {
        data {
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recsR435r7Nmd470B: airtable(data: {identifier: {eq: "recsR435r7Nmd470B"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recfaB7Tsu6va5vXI: airtable(data: {identifier: {eq: "recfaB7Tsu6va5vXI"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reczveSygveqQkFPB: allAirtable(filter: {data: {group: {eq: "studio18"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recPySxjwdAdJlsFF: airtable(data: {identifier: {eq: "recPySxjwdAdJlsFF"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5CVCGytuXmzg9f: airtable(data: {identifier: {eq: "rec5CVCGytuXmzg9f"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5WRELod2psevSk: airtable(data: {identifier: {eq: "rec5WRELod2psevSk"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recQi00bP8akWijXD: airtable(data: {identifier: {eq: "recQi00bP8akWijXD"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec179vFpOZ3cGzzb: airtable(data: {identifier: {eq: "rec179vFpOZ3cGzzb"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recD84QQVpb0e0kjd: airtable(data: {identifier: {eq: "recD84QQVpb0e0kjd"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recatzeYDQ0WxdC9h: airtable(data: {identifier: {eq: "recatzeYDQ0WxdC9h"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recOvawqHzdPg272O: airtable(data: {identifier: {eq: "recOvawqHzdPg272O"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recD1qc0Cxh1ORpfY: airtable(data: {identifier: {eq: "recD1qc0Cxh1ORpfY"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec5MiBx1ik3iJmpn: airtable(data: {identifier: {eq: "rec5MiBx1ik3iJmpn"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recGHgPFI1Zd8Tm9m: allAirtable(filter: {data: {story: {eq: "Studio Art Walk"}, block: {eq: 24.0}}}, sort: {fields: data___order, order: ASC}, limit: 2) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    recfXIYRUntXxcPkC: allAirtable(filter: {data: {group: {eq: "studio24"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                text {
                    childMarkdownRemark {
                        html
                    }
                }
            }
        }
    }

    reczOorxV1P9L2uLt: airtable(data: {identifier: {eq: "reczOorxV1P9L2uLt"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recEBTPkkSZAO9UdS: airtable(data: {identifier: {eq: "recEBTPkkSZAO9UdS"}}) {
        data {
            title
            text {
                childMarkdownRemark {
                    rawMarkdownBody
                }
            }
        }
    }

    reccUl6HRhOAn9Pfb: airtable(data: {identifier: {eq: "reccUl6HRhOAn9Pfb"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec6xa3WWvhf3jpbh: airtable(data: {identifier: {eq: "rec6xa3WWvhf3jpbh"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec1pIfxtAY6RLDGU: airtable(data: {identifier: {eq: "rec1pIfxtAY6RLDGU"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec3KF6rB5mZNVohv: airtable(data: {identifier: {eq: "rec3KF6rB5mZNVohv"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recxnfW0etbtdwKAX: airtable(data: {identifier: {eq: "recxnfW0etbtdwKAX"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    rec6v4JO1rYweqiA4: airtable(recordId: {eq: "rec6v4JO1rYweqiA4"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recfcQViiPkykcP9Z: airtable(recordId: {eq: "recfcQViiPkykcP9Z"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    recCtf9OLt6C1LcCM: airtable(recordId: {eq: "recCtf9OLt6C1LcCM"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }

    rec495M62J3QgeTOv: allAirtable(filter: {data: {group: {eq: "studio28"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recshUgdHKfZ0r2hm: allAirtable(filter: {data: {group: {eq: "studio29"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recDkySAf4jDZcnSR: airtable(recordId: {eq: "recDkySAf4jDZcnSR"}) {
        data {
            alt
        }
    }

    recNfk4AAvTna25uz: allAirtable(filter: {data: {group: {eq: "studio31"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    rec7hAUlQBLogxYyQ: allAirtable(filter: {data: {group: {eq: "studio32"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
            }
        }
    }

    recMVE78rscBD6F0I: airtable(data: {identifier: {eq: "recMVE78rscBD6F0I"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recPtQEo7njUvIzPa: airtable(data: {identifier: {eq: "recPtQEo7njUvIzPa"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recakLbLEsdVzCOUV: airtable(data: {identifier: {eq: "recakLbLEsdVzCOUV"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reczGUTGfrVYdaRSV: airtable(data: {identifier: {eq: "reczGUTGfrVYdaRSV"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
        }
    }

    recHmlPfE5aVxFBm4: airtable(data: {identifier: {eq: "recHmlPfE5aVxFBm4"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recSzyAMRYjr0x9cU: airtable(data: {identifier: {eq: "recSzyAMRYjr0x9cU"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec33UXJVbBnqseyq: airtable(recordId: {eq: "rec33UXJVbBnqseyq"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reczFfvY8Mp3vmkI1: airtable(recordId: {eq: "reczFfvY8Mp3vmkI1"}) {
        data {
            alt
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 85) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    rec7ryF21Kg15xPoK: airtable(data: {identifier: {eq: "rec7ryF21Kg15xPoK"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    reco5r14z9Gm0DcoT: airtable(data: {identifier: {eq: "reco5r14z9Gm0DcoT"}}) {
        data {
            file {
                localFiles {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
            alt
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recB6Thu2BcD4HZvk: airtable(data: {identifier: {eq: "recB6Thu2BcD4HZvk"}}) {
        data {
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

    recw4QnsvDVzTZcSJ: allAirtable(filter: {data: {group: {eq: "studio36"}}}, sort: {fields: data___order, order: ASC}) {
        nodes {
            recordId
            data {
                file {
                    localFiles {
                        childImageSharp {
                            fluid(quality: 85) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                alt
                title
                url
            }
        }
    }

    recQMPYluhJKwzabC: airtable(recordId: {eq: "recQMPYluhJKwzabC"}) {
        data {
            alt
            title
            text {
                childMarkdownRemark {
                    html
                }
            }
        }
    }

}  
`;